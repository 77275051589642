const data = [
  {
    src: "https://archdesignsstudio.com/assets/projects/dpsnoida/pic3.png",
    text: "pic 1",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects/dpsnoida/pic1.png",
    text: "pic 2",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects/dpsnoida/pic2.png",
    text: "pic 3",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects/dpsnoida/pic4.png",
    text: "pic 4",
  },
];

export default data;
