import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import "react-awesome-slider/dist/styles.css";
import SEO from "../../seo";
const details = {
  title: "Delhi Public School, Noida",
  address: "Noida",
  client: "Delhi Public School",
  area: "15.0 Acres",
  project: "Delhi Public School, Noida",
  category: "Institutions | Architecture | School",
  status: "Completed",
  backlink: "/projects/institutions/",
};

export default class Projectdpsnoida extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Institutional Projects | Delhi Public School, Noida"
          description="View details of the planning, design and development of the 15 acre campus of a leading school in the national capital region."
          img="https://archdesignsstudio.com/assets/linkpreview.png"
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
